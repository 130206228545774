import { Component, OnInit } from '@angular/core';
import { Challenge } from '../../_services';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-open-projects',
  templateUrl: './open-projects.component.html',
  styleUrls: ['./open-projects.component.scss']
})
export class OpenProjectsComponent implements OnInit {

  submitted = false;
  loading = false;
  projectid = '';
  records: any[];
  appliedrecords: any[];
  shortrecords: any[];
  selectedrecords: any[];
  rejectedrecords: any[];
  success = false;
  loading_component = "";

  constructor( private challengeService: Challenge, private route: ActivatedRoute) { }

  ngOnInit() {
    this.projectid = this.route.snapshot.paramMap.get('id');
    this.challengeService.getById(this.projectid).pipe(first()).subscribe(res => {
      this.records = res;
    });
    this.loadProposals();
	
  }
  
  loadProposals(){
	this.challengeService.getMyProjproposalList(this.projectid, "Applied").pipe(first()).subscribe(res => {
      this.appliedrecords = res;
    });
	this.challengeService.getMyProjproposalList(this.projectid, "Short Listed").pipe(first()).subscribe(res => {
      this.shortrecords = res;
    });
	this.challengeService.getMyProjproposalList(this.projectid, "Selected").pipe(first()).subscribe(res => {
      this.selectedrecords = res;
    });
	this.challengeService.getMyProjproposalList(this.projectid, "Declined").pipe(first()).subscribe(res => {
      this.rejectedrecords = res;
      this.loading_component = "";
    });
  
  }
  
  changeStatus(proposalID: string, Status: string){
    this.loading_component = "place-proposals-loading";
	  this.challengeService.changeProposalStatus(proposalID, Status).pipe(first()).subscribe(res => {
	  this.success = true;      
	  this.loadProposals();
  });
	  
  }
   
    
}
