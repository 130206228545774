import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  loading_component = "";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Utils.noWhitespaceValidator]],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  onSubmit(){

    this.submitted = true;
    this.loading_component = "place-proposals-loading";
    
    if (this.loginForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append('username', this.f.username.value);
    formData.append('password', this.f.password.value);

    this.authenticationService.login(formData).pipe(first()).subscribe((data: any) => {
          if(data.status=='ok') {
            this.loading = false;
            this.loading_component = "";
            this.dialogRef.close();
            this.alertService.success(data.message, true);
            this.router.navigate(['/profile']);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
            this.loading_component = "";
          }
    }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.loading_component = "";
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
