import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../_services';
import { Utils, MustMatch } from '../../_helpers';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  loading = false;
  loading_component = "";
  company_show = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<RegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
    } 
  }
		
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
      lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
      company_name: [''],
      type: ['Applicant', [Validators.required, Utils.noWhitespaceValidator]],
      email: ['', [Validators.required, , Validators.email]],
      password: ['', [Validators.required,Validators.min(6),Utils.noWhitespaceValidator]],
      confirm_password: ['', [Validators.required,Validators.min(6),Utils.noWhitespaceValidator]],
    }, {
      validator: MustMatch('password', 'confirm_password')
    });

    this.registerForm.get('type').valueChanges.subscribe(val => {
      if(val=='Company') {
        this.company_show = true;
        this.registerForm.get('company_name').setValidators([Validators.required]);
      } else {
        this.company_show = false;
      }
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit(){

    this.submitted = true;
    this.loading = true;
    this.loading_component = "place-proposals-loading";
    
    if (this.registerForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }

    const formData = new FormData();
    formData.append('firstname', this.f.firstname.value);
    formData.append('lastname', this.f.lastname.value);
    formData.append('type', this.f.type.value);
    formData.append('email', this.f.email.value);
    formData.append('password', this.f.password.value);

    if(this.f.type.value=='Company') {
      formData.append('company_name', this.f.company_name.value);
    }

    this.userService.register(formData).pipe(first()).subscribe((data: any) => {
          if(data.status=='ok') {
            this.dialogRef.close();
            // this.alertService.success(data.message, true);

            // Login Start

            const formData1 = new FormData();
            formData1.append('username', this.f.email.value);
            formData1.append('password', this.f.password.value);

            this.authenticationService.login(formData1).pipe(first()).subscribe((data: any) => {
                  if(data.status=='ok') {
                    this.loading = false;
                    this.loading_component = "";
                    this.alertService.success('Registered and Logged-in Successfully', true);
                    this.router.navigate(['/profile']);
                  } else {
                    this.alertService.error(data.message, true);
                    this.loading = false;
                    this.loading_component = "";
                  }
            }, error => {
                this.alertService.error(error);
                this.loading = false;
                this.loading_component = "";
            });

            // Login End


          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
            this.loading_component = "";
          }
    }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.loading_component = "";
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
