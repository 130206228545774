import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getById(id: string) {
        return this.http.get(`user/details/` + id);
    }

    register(user: any) {
        return this.http.post(`user/registration`, user);
    }

    getProfile() {
        return this.http.get<any[]>(`user/profile`);
    }

    update(user: any) {
        return this.http.post(`user/profile_update`, user);
    }

    LogoUpload(user: any) {
        return this.http.post(`user/profile_upload`, user);
    }

}