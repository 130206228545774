import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FeatureProjectComponent } from './components/feature-project/feature-project.component';
import { ProjectListingComponent } from './components/project-listing/project-listing.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChallengesComponent } from './components/challenges/challenges.component';
import { SearchSectionComponent } from './components/search-section/search-section.component';
import { PostChallengeComponent } from './components/post-challenge/post-challenge.component';
import { ProfileDescriptionComponent } from './components/profile-description/profile-description.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { OpenProjectsComponent } from './components/open-projects/open-projects.component';
import { ProjectDocumentsComponent } from './components/project-documents/project-documents.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AuthGuard } from './_guards';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { MyproposalComponent } from './components/myproposal/myproposal.component';
import { EditChallengeComponent } from './components/edit-challenge/edit-challenge.component';
import { ProfileComponent } from './components/profile/profile.component';

const routes: Routes = [
  { 
	path: '', 
	component: HomeComponent },
  { 
	path: 'feature-project', 
	component: FeatureProjectComponent },
  { 
	path: 'challenges',  
	component: ProjectListingComponent },
  { 
	path: 'header',  
	component: HeaderComponent },
  { 
	path: 'login',  
	component: LoginComponent },
  { 
	path: 'register',  
	component: RegisterComponent },
  { 
	path: 'footer',  
	component: FooterComponent },
  { 
	path: 'my-challenges',  
	component: ChallengesComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'search-section',  
	component: SearchSectionComponent },
  { 
	path: 'post-challenge',  
	component: PostChallengeComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'profile/:id',  
	component: ProfileDescriptionComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'challenge/proposals/:id',  
	component: OpenProjectsComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'challenge/:id',  
	component: ProjectDetailsComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'challenge/upload/:id',  
	component: ProjectDocumentsComponent, 
    canActivate: [AuthGuard] },
  { 
	path: 'user-login',  
	component: LoginPageComponent
  },
  { 
	path: 'user-register',  
	component: RegisterPageComponent
  },
  { 
	path: 'my-proposals',  
	component: MyproposalComponent, 
    canActivate: [AuthGuard] 
  },
  { 
	path: 'edit-challenge/:id',  
	component: EditChallengeComponent, 
    canActivate: [AuthGuard] 
  },
  { 
	path: 'profile',  
	component: ProfileComponent, 
    canActivate: [AuthGuard] 
  }
  
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 