import { Component, OnInit } from '@angular/core';
import { Challenge } from '../../_services';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-myproposal',
  templateUrl: './myproposal.component.html',
  styleUrls: ['./myproposal.component.scss']
})
export class MyproposalComponent implements OnInit {

  proposals: any;

  constructor(private challengeService: Challenge) { }

  ngOnInit() {

  	this.challengeService.getMyProposalList().pipe(first()).subscribe((res: any) => {
      if(res.status='ok') {
      	this.proposals = res.result_set;
      }
  	});
  }


 

 
}
