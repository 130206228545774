import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class Challenge {
    constructor(private http: HttpClient) { }

    getById(id: any) {
        return this.http.get<any[]>(`project/details/` + id);
    }

    postChallenge(challenge: any) {
        return this.http.post(`project/add`, challenge);
    }

    editChallenge(challenge: any) {
        return this.http.post(`project/update`, challenge);
    }

    postProposal(proposal: any) {
        return this.http.post(`proposal/add`, proposal);
    }

    getMyProposalList() {
        return this.http.get<any[]>(`proposal/myproposals`);
    }

    getChallengeList() {
        return this.http.get<any[]>(`home/projects`);
    }

    getMyChallengeList() {
        return this.http.get<any[]>(`project/myprojects`);
    }
    
    getDocuments(projectID: string) {
        return this.http.get<any[]>(`project/documents/`+projectID);
    }

    ProposalDocAdd(document: any) {
        return this.http.post(`proposal/document_upload`, document);
    }

    getProposalDocs(projectID: string) {
        return this.http.get<any[]>(`proposal/documents/`+projectID);
    }

    removePropasalDoc(documentID: string) {
        return this.http.get<any[]>(`proposal/document_remove/`+documentID);
    }

    DeleteChallenge(projectID: string) {
        return this.http.get<any[]>(`project/delete/`+projectID);
    }
   

    public uploadDocuments(document: File, projectID: string): Observable<any> {
        const formData = new FormData();
		formData.append('document', document);
        formData.append('project_id', projectID);
		return this.http.post(`project/document_upload`, formData);
    }
	
    removeDocuments(documentID: string) {
        return this.http.get<any[]>(`project/document_remove/`+documentID);
    }

	 getMyProjproposalList(projectID: string, statusval: string ) {
        return this.http.get<any[]>(`project/proposals/`+projectID+"?filter_proposal_status="+statusval);
    }
     
	changeProposalStatus(proposal_id: string, proposal_status: string) {
		const formData = new FormData();
		formData.append('proposal_id', proposal_id);
        formData.append('proposal_status', proposal_status);
		
        return this.http.post(`proposal/status_change`, formData);
    }





}


