import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material-module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import {TimeAgoPipe} from 'time-ago-pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';

import { HomeComponent } from './components/home/home.component';
import { FeatureProjectComponent } from './components/feature-project/feature-project.component';
import { ProjectListingComponent } from './components/project-listing/project-listing.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChallengesComponent } from './components/challenges/challenges.component';
import { SearchSectionComponent } from './components/search-section/search-section.component';
import { PostChallengeComponent } from './components/post-challenge/post-challenge.component';
import { ProfileDescriptionComponent } from './components/profile-description/profile-description.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { OpenProjectsComponent } from './components/open-projects/open-projects.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProjectDocumentsComponent } from './components/project-documents/project-documents.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { MyproposalComponent } from './components/myproposal/myproposal.component';
import { EditChallengeComponent } from './components/edit-challenge/edit-challenge.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    FeatureProjectComponent,
    ProjectListingComponent,
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    FooterComponent,
    ChallengesComponent,
    SearchSectionComponent,
    PostChallengeComponent,
    ProfileDescriptionComponent,
    ProjectDetailsComponent,
    OpenProjectsComponent,
    ProjectDocumentsComponent,
    LoginPageComponent,
	TimeAgoPipe,
	RegisterPageComponent,
	MyproposalComponent,
	EditChallengeComponent,
	ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MaterialModule,
    MDBBootstrapModule.forRoot(),
  	NgbModule,
  	AngularFontAwesomeModule,
  	DragScrollModule,
  	FormsModule,
  	ReactiveFormsModule,
    HttpClientModule,
    SlickCarouselModule,
  	NgMultiSelectDropDownModule.forRoot(),
	NgxEditorModule
  ],
  entryComponents: [
  	LoginComponent,
  	ProjectListingComponent
	],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export class PizzaPartyAppModule { }
