import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../_services';
import { Utils, MustMatch } from '../../_helpers';
@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent implements OnInit {

  registerForm1: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService
    
  ) { 
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
    } 
  }
		
  ngOnInit() {
    this.registerForm1 = this.formBuilder.group({
      firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
      lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
      email: ['', [Validators.required, , Validators.email]],
      password: ['', [Validators.required,Validators.min(6),Utils.noWhitespaceValidator]],
      confirm_password: ['', [Validators.required,Validators.min(6),Utils.noWhitespaceValidator]],
    }, {
      validator: MustMatch('password', 'confirm_password')
    });
  }

  get f() { return this.registerForm1.controls; }

  onSubmit(){

    this.submitted = true;
    
    if (this.registerForm1.invalid) {
        return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append('firstname', this.f.firstname.value);
    formData.append('lastname', this.f.lastname.value);
    formData.append('email', this.f.email.value);
    formData.append('password', this.f.password.value);

    this.userService.register(formData).pipe(first()).subscribe((data: any) => {
          if(data.status=='ok') {
          //  this.dialogRef.close();
           // this.alertService.success(data.message, true);
            this.router.navigate(['/']);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
          }
    }, error => {
        this.alertService.error(error);
        this.loading = false;
    });
  }
}