import {Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Challenge, UserService, AuthenticationService, AlertService } from '../../_services';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent implements OnInit {
		
  selected = 'Latest';
  dropdownListlang = [];
  dropdownList = [];
  selectedItems = [];
  selectedItemslang = [];
  dropdownSettings = {};
  dropdownSettingslang = {};
  user: any[];
  profile_pic = '';
  records: any[];
  loading_component = "";

  constructor( private userService: UserService, private authenticationService: AuthenticationService, private _formBuilder: FormBuilder,private challengeService: Challenge, private alertService: AlertService) {}

  ngOnInit() {
	  this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
	this.dropdownListlang = [
      { item_id: 6, item_text: 'Mumbai2' },
      { item_id: 7, item_text: 'Bangaluru2' },
      { item_id: 8, item_text: 'Pune2' },
      { item_id: 9, item_text: 'Navsari2' },
      { item_id: 10, item_text: 'New Delhi2' }
    ];
	this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };
	this.dropdownSettingslang = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };

    this.loading_component = "place-proposals-loading";
    
    this.getAllChallenges();
    
    this.userService.getProfile().pipe(first()).subscribe((user: any) => {
        this.user = user.result_set;
        this.profile_pic = user.result_set.merchant_logo;
    });
  }

  getAllChallenges() {
    this.challengeService.getMyChallengeList().pipe(first()).subscribe((res: any) => {
      if(res.status=='ok') {
        this.loading_component = '';
        this.records = res.result_set;        
      }
    });
  }

  DeleteChallenge(project_id: string) {
    this.loading_component = "place-proposals-loading";
    this.alertService.deleteConfirm().then(data=>{
        if(data) {
          this.challengeService.DeleteChallenge(project_id).pipe(first()).subscribe((data: any) => {
            if(data.status=='ok') {
              this.alertService.success(data.message, true);
              this.getAllChallenges();        
            } else {
              this.alertService.error(data.message, true);
            }
          });
        } 
    });
  }
  
	resetSelection(){
		this.selectedItems = [];
		this.selectedItemslang = [];
	}
	
}
