import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Challenge } from '../../_services';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, HomeService } from '../../_services';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';


@Component({
  selector: 'app-post-challenge',
  templateUrl: './post-challenge.component.html',
  styleUrls: ['./post-challenge.component.scss']
})
export class PostChallengeComponent {
    
    challengeForm: FormGroup;
    submitted = false;
    loading = false;
    name = 'Angular 4';
    urls = [];
    categories = [];
	loading_component="";
	 visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  criterias = null;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.criterias.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(criteria): void {
    const index = this.criterias.indexOf(criteria);

    if (index >= 0) {
      this.criterias.splice(index, 1);
    }
  }
  
    constructor(private router: Router, private formBuilder: FormBuilder, private challengeService: Challenge, private route: ActivatedRoute, private alertService: AlertService, private homeService: HomeService) { }

  
  ngOnInit() {
    this.challengeForm = this.formBuilder.group({
        projectTitle: ['', Validators.required],
        projectShortDescription: ['', Validators.required],
        projectDescription: ['', Validators.required],
        project_category_id: ['', Validators.required],
        projectLocation: ['', Validators.required],
        projectBudget: ['', Validators.required],
        projectDuration: ['', Validators.required]
    });
    this.getCategory();
  }

  getCategory() {
    this.homeService.getAllCategories().pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.categories = data.result_set;
      }
    });
  }

  get f() { return this.challengeForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.loading_component = "place-proposals-loading";
    // stop here if form is invalid
    if (this.challengeForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }else{
      //api call
	    const formData = new FormData();
      formData.append('project_title', this.f.projectTitle.value);
      formData.append('project_short_description', this.f.projectShortDescription.value);
      formData.append('project_description', this.f.projectDescription.value);
      formData.append('project_category_id', this.f.project_category_id.value);
      formData.append('project_location', this.f.projectLocation.value);
      formData.append('project_fund', this.f.projectBudget.value);
	    formData.append('project_duration', this.f.projectDuration.value);
      formData.append('project_skill_set', this.criterias);
          
  	  this.challengeService.postChallenge(formData).pipe(first()).subscribe((data: any) => {
        if(data.status=='ok') {
          this.loading = false;
          this.loading_component = "";
  	      this.router.navigate(['challenge/upload/'+data.project_id]);
        } else {
          this.loading = false;
          this.loading_component = "";
  	      this.alertService.error(data.message, true);
  	   
        }
      }, error => {
        this.loading = false;
        this.loading_component = "";
      });
    }
}

onSkipSubmit() {
    this.submitted = true;
    this.loading = true;
    this.loading_component = "place-proposals-loading";
    // stop here if form is invalid
    if (this.challengeForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }else{
      //api call
	    const formData = new FormData();
      formData.append('project_title', this.f.projectTitle.value);
      formData.append('project_description', this.f.projectDescription.value);
      formData.append('project_category_id', this.f.project_category_id.value);
      formData.append('project_location', this.f.projectLocation.value);
      formData.append('project_fund', this.f.projectBudget.value);
	    formData.append('project_duration', this.f.projectDuration.value);
          
  	  this.challengeService.postChallenge(formData).pipe(first()).subscribe((data: any) => {
        if(data.status=='ok') {
          this.loading = false;
          this.loading_component = "";
  	      this.router.navigate(['/my-challenges']);
        } else {
          this.loading = false;
          this.loading_component = "";
  	      this.alertService.error(data.message, true);
  	   
        }
      }, error => {
        this.loading = false;
        this.loading_component = "";
      });
    }
}

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();

          reader.onload = (event:any) => {
            console.log(event.target.result);
             this.urls.push(event.target.result); 
          }

          reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
  
  

}
