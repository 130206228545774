import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

import { AlertService, AuthenticationService, UserService, HomeService } from '../../_services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser= null;
  logged_in = false;
  user = [];
  profile_pic = null;
  settings = [];
  logo = null;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService,
    private homeService: HomeService,
    public dialog: MatDialog
  ) { 
    this.authenticationService.currentUser.subscribe((x: any) => { 
      this.currentUser = x;
      if(this.currentUser) {
        this.logged_in = true;
        this.userService.getProfile().pipe(first()).subscribe((user: any) => {
        if(user.status=='ok') {
            this.user = user.result_set;
            this.profile_pic = user.result_set.merchant_profile_picture;
          } else {
            this.logged_in = false;
          }
        }, error => {
          this.logged_in = false;
        });
      } else {
        this.logged_in = false;
      }      
    });
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'logincomponent', disableClose: true
    });

  }	

  openDialogreg(): void {
    const dialogRef = this.dialog.open(RegisterComponent, {
		panelClass: 'registercomponent', disableClose: true
    });

  }

  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    this.homeService.getSettings().pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.settings = data.result_set;
        this.logo = data.result_set.site_logo;
      }
    });
  }

  logout() {
    this.logged_in = false;
    this.authenticationService.logout();
    this.alertService.success("Successfully Logged-out ", true);
    this.router.navigate(['/']);
  }
  
}
