import {Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Challenge } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-project-listing',
  templateUrl: './project-listing.component.html',
  styleUrls: ['./project-listing.component.scss']
})
export class ProjectListingComponent implements OnInit {
  show: boolean = true;
  selected = 'Latest';
  dropdownListlang = [];
  dropdownList = [];
  selectedItems = [];
  selectedItemslang = [];
  dropdownSettings = {};
  dropdownSettingslang = {};
  records: any[];
  loading_component = "";

  constructor(private _formBuilder: FormBuilder,private challengeService: Challenge) {}

  ngOnInit() {

    this.loading_component="place-proposals-loading";

	  this.dropdownList = [
      { item_id: 1, item_text: 'Pre-MVP' },
      { item_id: 2, item_text: 'Working MVP' },
      { item_id: 3, item_text: 'Generating Sales' },
      { item_id: 4, item_text: 'Fast Growth' },
    ];
	this.dropdownListlang = [
      { item_id: 6, item_text: 'English' },
      { item_id: 7, item_text: 'Chinese' },
      { item_id: 8, item_text: 'Spanish' },
      { item_id: 9, item_text: 'Arabic' },
      { item_id: 10, item_text: 'French' }
    ];
	this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };
	this.dropdownSettingslang = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };

    this.challengeService.getChallengeList().pipe(first()).subscribe((res: any) => {
		  if(res.status=='ok') {
      this.records = res.result_set;
      this.loading_component = "";
      }
      
  });


	
  }
  
	resetSelection(){
		this.selectedItems = [];
		this.selectedItemslang = [];
	}
	closetips(){
		this.show = false;
	}
	
}
