import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService, AuthenticationService, UserService } from '../../_services';
import { Utils, MustMatch } from '../../_helpers';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  name = 'Angular 4';
  url = '';
  user = [];
  loading = false;
  submitted = false;
  loading_component = "";
  preview_image= '';
  doc_loading= "";
  profile_picture = null;
  merchant_logo = null;
  preview_logo = null;
  company_show = false;

  constructor(private formBuilder: FormBuilder,private authenticationService: AuthenticationService, private alertService: AlertService, private userService: UserService) {}
  
  ngOnInit(){
    this.profileForm = this.formBuilder.group({
        firstname: ['', [Validators.required, Utils.noWhitespaceValidator]],
        lastname: ['', [Validators.required, Utils.noWhitespaceValidator]],
        email: ['', [Validators.required, , Validators.email]],
        merchant_logo: [''],
        merchant_company: [''],
        current_password: [''],
        new_password: [''],
        confirm_password: [''],
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });

	  this.getProfile();
  }

  get f() { return this.profileForm.controls; }

  getProfile() {
    this.userService.getProfile().pipe(first()).subscribe((user: any) => {
      if(user.status=='ok') {
        this.user = user.result_set;
        this.profileForm.setValue({
          firstname: user.result_set.merchant_firstname,
          lastname: user.result_set.merchant_lastname,
          email: user.result_set.merchant_email,
          merchant_logo: user.result_set.merchant_logo,
          merchant_company: user.result_set.merchant_company_name,
          current_password: '',
          new_password: '',
          confirm_password: ''
        });
        this.preview_image = user.result_set.merchant_profile_picture;
        if(user.result_set.merchant_type=='Company') {
          this.company_show = true;
          this.profileForm.get('merchant_company').setValidators([Validators.required]);
          this.profileForm.get('merchant_logo').setValidators([Validators.required]);
          this.preview_logo = user.result_set.merchant_logo;
        } else {
          this.company_show = false;
        }
      }
    });
  }

  ProfileUpload(files: any[]) {
    this.profile_picture = files[0];
    this.doc_loading = "place-proposals-loading";
    const formData1 = new FormData();

    formData1.append("logo", this.profile_picture);

    this.userService.LogoUpload(formData1).pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.alertService.success(data.message, true);
        this.doc_loading = "";
        this.loading = false;
        this.getProfile();
      } else {    
        this.doc_loading = "";
        this.alertService.error(data.message, true);       
        this.loading = false;
      }
    }, error => {
      this.doc_loading = "";
      this.alertService.error(error);
      this.loading = false;
    });
  }

  LogoChange(files: FileList) {
    this.merchant_logo = files[0];
    this.profileForm.get('merchant_logo').setValue(this.merchant_logo.name);
    var reader = new FileReader();
    reader.readAsDataURL(this.merchant_logo); 
    reader.onload = (_event) => { 
      this.preview_logo = reader.result; 
    }
  }

  
  /* onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  } */

  onSubmit(){

    this.submitted = true;
    this.loading = true;
    this.loading_component = "place-proposals-loading";
    
    if (this.profileForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }

    const formData = new FormData();
    formData.append('firstname', this.f.firstname.value);
    formData.append('lastname', this.f.lastname.value);
    formData.append('email', this.f.email.value);

    if(this.company_show) {
      if(this.merchant_logo) {
        formData.append('merchant_logo', this.merchant_logo);
      }
      formData.append('merchant_company_name', this.f.merchant_company.value);      
    }

    if(this.f.current_password.value!='') {
      formData.append('current_password', this.f.current_password.value);
      formData.append('new_password', this.f.new_password.value);
    }

    this.userService.update(formData).pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.alertService.success(data.message, true);
        this.getProfile();
        this.loading = false;
        this.loading_component = "";        
      } else {
        this.alertService.error(data.message, true);
        this.loading = false;
        this.loading_component = "";
      }
    }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.loading_component = "";
    });
  }

}
