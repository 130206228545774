import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private toastr: ToastrService,private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {

        this.keepAfterNavigationChange = keepAfterNavigationChange;
        // this.subject.next({ type: 'success', text: message });
        this.toastr.success(message,'',{ closeButton: true, positionClass: 'toast-top-center' });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        // this.subject.next({ type: 'error', text: message });
        this.toastr.error(message,'',{ enableHtml: true, closeButton: true, positionClass: 'toast-top-center'});
    }

    deleteConfirm() {

        var res = new Promise((resolve, reject) => {
            Swal.fire({
              title: 'Are you sure, You want to delete?',
              text: "You won't be able to revert this!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.value) {
                resolve(true);
                this.keepAfterNavigationChange = false;
              } else {
                resolve(false);
                this.keepAfterNavigationChange = true;
              }
            })
        });

        return res;
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}