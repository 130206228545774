import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Challenge,AlertService } from '../../_services';
import { first } from 'rxjs/operators';
import {  ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
 
export class ProjectDetailsComponent implements OnInit {

	proposalForm: FormGroup;
	submitted = false;
	loading = false;
  name = 'Angular 4';
  urls = [];
  projectid = '';
  records: any[];
  document1 = null;
  proposalDocs = [];
  proposal_id = null;
  loading_component = "";
  doc_loading = "";
  projectdocs = [];

  constructor(private formBuilder: FormBuilder, private challengeService: Challenge, private alertService: AlertService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.projectid = this.route.snapshot.paramMap.get('id');
    
    this.getProjectinfo();
    this.getProposalDocs();
    this.getDocuments(this.projectid);

    this.proposalForm = this.formBuilder.group({
      proposalPrice: ['', Validators.required],
      proposalDays: ['', Validators.required],
      proposalDes: ['', Validators.required]
      
    });
  }

  getProjectinfo() {
    this.challengeService.getById(this.projectid).pipe(first()).subscribe((res: any) => {
      if(res.status=='ok') {
        this.records = res.result_set;
      }      
    });
  }

  DocUpload(files: any[]) {
    this.document1 = files[0];
    this.doc_loading = "place-proposals-loading";
    const formData1 = new FormData();

    formData1.append('proposal_project_id', this.projectid );
    formData1.append("document", this.document1);

    this.challengeService.ProposalDocAdd(formData1).pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.doc_loading = "";
        this.getProposalDocs();
        this.loading = false;
        this.alertService.success(data.message, true);
      } else {    
        this.doc_loading = "";
        this.alertService.error(data.message, true);       
        this.loading = false;
      }
    }, error => {
      this.doc_loading = "";
      this.alertService.error(error);
      this.loading = false;
    });
  }

  private getProposalDocs() {
    this.challengeService.getProposalDocs(this.projectid).pipe(first()).subscribe((documents: any) => {
      if(documents.status=='ok'){
        this.proposalDocs = documents.result_set;
      }
    });
  }

  private deleteProposalDoc(documentID){
    this.doc_loading = "place-proposals-loading";
    this.challengeService.removePropasalDoc(documentID).pipe(first()).subscribe((documents: any) => {
      if(documents.status=='ok') {
        this.doc_loading = "";
        this.getProposalDocs();
        this.alertService.success(documents.message, true);
      } else {
        this.doc_loading = "";
        this.alertService.error(documents.message, true);   
      }
    });
  }
  
  get f() { return this.proposalForm.controls; }

  private getDocuments(projectID) {
    this.challengeService.getDocuments(projectID).pipe(first()).subscribe((documents: any) => {
      if(documents.status=='ok'){
        this.projectdocs = documents.result_set;
      }
    });
  }
  
  onSubmit() {
    this.loading_component = "place-proposals-loading";
    this.submitted = true;
    this.loading = true;
    if (this.proposalForm.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }else{
      const formData = new FormData();
      formData.append('proposal_price', this.f.proposalPrice.value);
      formData.append('proposal_duration', this.f.proposalDays.value);
      formData.append('proposal_description', this.f.proposalDes.value);
      formData.append('proposal_project_id', this.projectid );

      /*for (var i = 0; i < this.documents.length; i++) { 
        formData.append("documents[]", this.documents[i]);
      }*/

      this.challengeService.postProposal(formData).pipe(first()).subscribe((data: any) => {
        if(data.status=='ok') {
          this.getProjectinfo();
          this.loading_component = "";
          this.loading = false;
          this.alertService.success(data.message, true);
        } else {    
          this.loading_component = "";
          this.alertService.error(data.message, true);       
          this.loading = false;
        }
      }, error => {
        this.loading_component = "";
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
}
