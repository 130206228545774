import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class HomeService {
    constructor(private http: HttpClient) { }

    getAllProjects(offset: number, limit: number) {
        return this.http.get<any>(`home/projects?offset=`+offset+"&limit="+limit);
    }

    getAllCategories() {
        return this.http.get<any>(`home/category`);
    }

    getSettings() {
    	return this.http.get<any>(`home/settings`);
    }
}