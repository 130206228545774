import { Component, OnInit } from '@angular/core';

import { Challenge, UserService } from '../../_services';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, } from '@angular/router';
@Component({
  selector: 'app-profile-description',
  templateUrl: './profile-description.component.html',
  styleUrls: ['./profile-description.component.scss']
})
export class ProfileDescriptionComponent implements OnInit {
	
	submitted = false;
	loading = false;
	 name = 'Angular 4';
    urls = [];
    userid = '';
    records=[];
	success= false;
  constructor(private userService: UserService, private challengeService: Challenge, private route: ActivatedRoute) { }

  ngOnInit() {
    this.userid = this.route.snapshot.paramMap.get('id');
    this.userService.getById(this.userid).pipe(first()).subscribe((res: any) => {
    this.records = res;      
  });

 
  }
  
 
}


