import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { HomeService } from '../../_services';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	settings = [];

  constructor(private homeService: HomeService) { }

  ngOnInit() {
  	this.getSettings();
  }

  getSettings() {
    this.homeService.getSettings().pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.settings = data.result_set;
      }
    });
  }

}
