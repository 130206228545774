import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { HomeService } from '../../_services';

@Component({
  selector: 'app-feature-project', 
  templateUrl: './feature-project.component.html',
  styleUrls: ['./feature-project.component.scss']
})
export class FeatureProjectComponent implements OnInit {

    
    
	projects: [];

  constructor(private homeService: HomeService) { }

  ngOnInit() {
  	this.loadAllProjects();
  }

  private loadAllProjects() {
		this.homeService.getAllProjects(0,6).pipe(first()).subscribe((projects: any) => {
			if(projects.status=='ok') { 
				this.projects = projects.result_set;
			}
		});
	}	

}

