import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Challenge,AlertService } from '../../_services';
import { first } from 'rxjs/operators';
import {  ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss']
})
 
export class ProjectDocumentsComponent implements OnInit {

	submitted = false;
	loading = false;
  projectid = '';
  records: any[];
  loading_component = "";
  document1 = null;

  constructor(private formBuilder: FormBuilder, private challengeService: Challenge, private alertService: AlertService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.projectid = this.route.snapshot.paramMap.get('id');
    this.getDocuments();
  }

  processFile(files: any[]) {
    this.document1 = files[0];
    this.loading_component = "place-proposals-loading";

    this.challengeService.uploadDocuments(this.document1, this.projectid).pipe(first()).subscribe((data: any) => {
      if(data.status=='ok') {
        this.loading_component = "";
        this.getDocuments();
        this.loading = false;
        this.alertService.success(data.message, true);
      } else {    
        this.loading_component = "";
        this.alertService.error(data.message, true);       
        this.loading = false;
      }
    }, error => {
      this.loading_component = "";
      this.alertService.error(error);
      this.loading = false;
    });
  }

  private deleteDocuments(documentID){
    this.loading_component = "place-proposals-loading";
    this.challengeService.removeDocuments(documentID).pipe(first()).subscribe((documents: any) => {
      if(documents.status=='ok') {
        this.loading_component = "";
        this.getDocuments();
        this.alertService.success(documents.message, true);
      } else {
        this.loading_component = "";
        this.alertService.error(documents.message, true);   
      }
    });
  }
  
  private getDocuments() {
    this.challengeService.getDocuments(this.projectid).pipe(first()).subscribe((documents: any) => {
      if(documents.status=='ok'){
        this.records = documents.result_set;
      }
    });
  }
  
  
}
