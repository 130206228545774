import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../../_services';
import { Utils } from '../../_helpers';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})


export class LoginPageComponent implements OnInit {
	loginForm1: FormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  loading_component = "";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
 
  ) {
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm1 = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Utils.noWhitespaceValidator]],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm1.controls; }

  onSubmit(){

    this.loading = true;
    this.submitted = true;
    this.loading_component = "place-proposals-loading";
    if (this.loginForm1.invalid) {
      this.loading = false;
      this.loading_component = "";
      return;
    }

    const formData = new FormData();
    formData.append('username', this.f.username.value);
    formData.append('password', this.f.password.value);

    this.authenticationService.login(formData).pipe(first()).subscribe((data: any) => {
          if(data.status=='ok') {
            this.loading = false;
            this.loading_component = "";
            //this.alertService.success(data.message, true);
            this.router.navigate(["/profile"]);
          } else {
            this.alertService.error(data.message, true);
            this.loading = false;
            this.loading_component = "";
          }
    }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.loading_component = "";
    });
  }
}
